// let head = 'https://birdexp.dev.tzlims.com/api/v1'
let head = 'https://birdexp.tzlims.com/api/v1'

export let Interface = {
	//产品列表
	prod_list: head + '/prod/list',
	//添加，更新
	prod_save: head + '/prod/save',
	//删除
	prod_del: head + '/prod/del',
	//详情
	prod_detail: head + '/prod/detail',
	//上传图片
	prod_upload: head + '/upload',
	//图片列表
	prod_imgList: head + '/img/list',

	// 客户列表
	customer_list: head + '/admin/customer/list',
	//添加客户
	customer_edit: head + '/admin/customer',
	//删除
	customer_del: head + '/admin/customer/del',
	//客户子账号列表
	customer_zi_list: head + '/customer/team/list',
	//子账号编辑
	customer_zi_edit: head + '/customer/team',
	//子账号删除
	customer_zi_del: head + '/customer/team/del',

	//上传图片
	upload_img: head + '/res/img/upload',
	//获取上传签名
	oss_sign: head + '/res/oss/get-upload-token',

    //统计分析
	project_analyze:head + '/customer/project/analyze',

	//项目列表
	project_list: head + '/customer/project/list',
	//编辑项目
	project_edit: head + '/customer/project',
	//删除项目
	project_del: head + '/customer/project/del',
	//项目日志
	project_log: head + '/sys/log/list',
	
	//通过项目id获取调查问卷列表
	project_question_list: head + '/project/admin/question/list',

	//通过id删除关联的调查问卷
	project_question_del: head + '/project/admin/question/del',

	//调查问卷填写状态
	question_status:head + '/project/admin/question/member/list',

	//关联调查问卷添加，修改
	project_question_update: head + '/project/admin/question',

	//通过项目id获取问卷填写列表
	question_record_list: head + '/project/admin/question/record/list',

	//通过id问卷调查记录
	question_record: head + '/project/admin/question/record',

	//项目状态
	project_status: head + '/admin/project/audit',
	//项目志愿者参与列表
	project_zyz_list: head + '/project/admin/volunteer/list',
	//项目志愿者审核参与
	project_zyz_apply: head + '/project/admin/volunteer/audit',
	//我的项目
	my_project: head + '/user/project/list',
	//志愿者打卡日记
	clock_list: head + '/volunteer/record/list',

	//问卷保存
	question_edit: head + '/customer/question',
	//问卷列表
	question_list: head + '/customer/question/list',
	//删除问卷
	question_del: head + '/customer/question/del',

	//系统志愿者列表
	zyz_list: head + '/admin/volunteer/list',
	//客户-关联志愿者
	customer_zyz_list:head + '/customer/volunteer/list',
	//我的志愿者信息
	zyz_my_info: head + '/user/volunteer',
	//更新志愿者信息
	zyz_info_edit: head + '/user/volunteer',
	//系统志愿者审核
	admin_audit_zyz: head + '/admin/volunteer/audit',
	//删除志愿者
	zyz_delete:head + '/admin/volunteer/del',

	//申请成为志愿者
	zyz_apply: head + '/user/identity/volunteer/reg',
	//申请参与项目
	zyz_project_apply: head + '/volunteer/project/apply',
	//打卡
	zyz_clock: head + '/volunteer/record',
	//未打卡志愿者
	zyz_clock_status:head + '/project/admin/checkin/member/list',
	//获取问卷
	zyz_question: head + '/volunteer/project/question',

	//调试身份
	debug_sudo: head + '/debug/sudo',

	//登录
	login: head + '/login/phone',
	//切换身份
	identity: head + '/user/identity/switch',

	//样品列表
	sample_list: head + '/customer/sample/list',
	//样品更新
	sample_edit: head + '/customer/sample',
	//样品删除
	sample_del: head + '/customer/sample/del',

	//广场
	square_project_list: head + '/project/list',
	//分类标签
	tags_list: head + '/project/tags',
	//广场项目详情
	project_detail: head + '/project/detail',
	//广场，id获取参与获取调查问卷列表
	square_question_list: head + '/volunteer/project/question/list',

	//系统
	//项目标签
	project_tag_list: head + '/sys/conf/project_tag/list',
	//项目标签保存
	project_tag_edit: head + '/sys/conf/project_tag',
	//项目标签删除
	project_tag_del: head + '/sys/conf/project_tag/del',

	//志愿者标签
	zyz_tag_list: head + '/sys/conf/volunteer_tag/list',
	//志愿者标签保存
	zyz_tag_edit: head + '/sys/conf/volunteer_tag',
	//志愿者标签删除
	zyz_tag_del: head + '/sys/conf/volunteer_tag/del',

	//项目类别
	project_type_list: head + '/sys/conf/project_type/list',
	//项目类别保存
	project_type_edit: head + '/sys/conf/project_type',
	//项目类别删除
	project_type_del: head + '/sys/conf/project_type/del',

	//公告
	//发布系统公告
	sys_notice_add: head + '/sys/conf/notice',
	//系统公告列表
	sys_notice_list: head + '/sys/notice/list',
	//读取系统公告
	sys_notice_read:head + '/sys/notice/read',
	//群公告
	pro_notice_add: head + '/project/admin/notice',
	//群公告列表
	pro_notice_list: head + '/project/admin/notice/list',
	//志愿者获取群公告
	zyz_notice_list: head + '/volunteer/project/notice/list',
	//志愿者已读群公告
	zyz_notice_read: head + ' /volunteer/project/notice/read',

	//微信获取用户信息
	wechat_info: head + '/login/wechat/mini',

	//预约
	//管理员获取预约清单
	manage_yuyue_list: head + '/project/admin/reserve/list',
	//更新预约
	edit_yuye: head + '/project/admin/reserve',
	//管理员预约记录列表
	yuye_record_list: head + '/project/admin/reserve/record/list',
	//志愿者预约
	zyz_yuyue: head + '/volunteer/reserve/record',
	//成员预约情况
	zyz_yuyue_status:head + '/project/admin/reserve/member/list',

	//志愿者获取预约清单
	zyz_yuyue_list: head + '/volunteer/reserve/list',
	//我的预约记录
	zyz_yuyue_record: head + '/volunteer/reserve/record/list',
	//预约签到
	admin_adopt:head + '/project/admin/reserve/adopt',

	//配置打卡
	clock_set: head + '/project/admin/conf/checkin',
	//获取打卡配置
	pro_clock_list: head + '/project/admin/conf/checkin/list',
	//项目-打卡记录
	pro_clock_record: head + '/project/admin/checkin/record/list',

	//项目组列表
	group_list: head + '/customer/group/list',
	//更新项目组
	group_edit: head + '/customer/group',
	//删除项目组
	group_del: head + '/customer/group/del',
 
	//通用标签设置
	tag_save: head + '/sys/conf/tag',
	tag_del: head + '/sys/conf/tag/del',
	tag_list: head + '/sys/conf/tag/list',
	tag_undel:head + '/sys/conf/tag/undelete'
	
}
